<script>
import ApiService from "@services/api.service";
import * as download from "downloadjs";

export default {
  name: "PlainLeadsReport",
  data() {
    return {
      loader: {
        page: false,
        button: false
      },
      groupOpts: [
        { key: "expiredDate", desc: "Due Date" },
        { key: "priority", desc: "Leads Priorities" },
        { key: "leadsType.description", desc: "Leads Types" },
        { key: "leadsType.leadsSource", desc: "Leads Sources" },
        { key: "status", desc: "Status" }
      ],
      parameter: {
        productList: [],
        userList: [],
        leadsTypeList: []
      },
      filter: {
        dateFrom: null,
        dateTo: null,
        productCode: null,
        userCode: null,
        leadsTypeCode: null,
        status: null
      }
    };
  },
  computed: {},
  mounted() {
    this.loadProduct();
    this.loadLeadsType();
  },
  methods: {
    loadProduct() {
      this.changeLoader(true);
      ApiService.get("Product")
        .then(resp => {
          this.parameter.productList = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    loadLeadsType() {
      this.changeLoader(true);
      ApiService.get("leadstype/role")
        .then(resp => {
          this.parameter.leadsTypeList = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    generate() {
      this.loader.button = true;
      ApiService.downloadPost("/Report/plain", this.filter)
        .then(resp => {
          this.download(resp.data, "PlainLeads.xlsx");
        })
        .catch(err => {
          this.showToast("error", err.message);
        })
        .finally(() => {
          this.loader.button = false;
        });
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-overlay :show="loader.button">
        <b-form @submit.stop.prevent="generate">
          <b-row>
            <b-col md="6">
              <b-form-group label="Start Date">
                <b-form-datepicker v-model="filter.dateFrom"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="End Date">
                <b-form-datepicker v-model="filter.dateTo"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Sales NIK">
                <b-form-input v-model="filter.userNIK" class="form-control"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Product">
                <v-select
                  v-if="parameter.productList.length > 0"
                  v-model="filter.productCode"
                  label="description"
                  :reduce="x => x.code"
                  :options="parameter.productList"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Leads Type">
                <v-select
                  v-if="parameter.leadsTypeList.length > 0"
                  v-model="filter.leadsTypeCode"
                  label="description"
                  :reduce="x => x.code"
                  :options="parameter.leadsTypeList"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Status">
                <v-select
                  v-model="filter.status"
                  label="description"
                  :reduce="x => x.code"
                  :options="[
                    { code: 0, description: 'OPEN' },
                    { code: 1, description: 'PENDING' },
                    { code: 2, description: 'CLOSED' },
                    { code: 3, description: 'CLOSED BY SYSTEM' }
                  ]"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-btn class="mx-auto" type="submit" variant="primary">Generate Excel File</b-btn>
        </b-form></b-overlay
      >
    </b-card>
  </div>
</template>
